@media screen and (min-width: 1050px) {

    .start-contact-page {
        padding-top: 15rem;
        background-color: #46BBCB;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        padding-bottom: 15rem;
        z-index: -2;
    }

    .contact-me-h2 {
        font-family: 'Space Mono', monospace;
        font-size: 4rem;
        font-weight: 200;
        padding-top: 2rem;
    }

    .letter {
        height: 5rem;
    }

    .contact-buttons-flex {
        display: flex;
        justify-content: space-around;
        width: 100%;
        align-items: center;
    }

    .contact-btn-container {
        display: flex;
        justify-content: space-between;
        width: 45%;
    }


    .contact-btn {
        align-items: center;
        background-color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: .25rem;
        box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;
        display: inline-flex;
        font-family: 'Space Mono', monospace;
        font-size: 16px;
        font-weight: 600;
        justify-content: center;
        line-height: 1.25;
        margin: 0;
        min-height: 3rem;
        padding: calc(.875rem - 1px) calc(1.5rem - 1px);
        position: relative;
        text-decoration: none;
        transition: all 250ms;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        vertical-align: baseline;
        width: auto;
        margin-bottom: 5%;
    }


    .contact-btn:hover,
    .contact-btn:focus {
        border-color: rgba(0, 0, 0, 0.15);
        box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
        color: rgba(0, 0, 0, 0.65);
    }

    .contact-btn:hover {
        transform: translateY(-1px);
    }

    .contact-btn:active {
        background-color: #F0F0F1;
        border-color: rgba(0, 0, 0, 0.15);
        box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
        color: rgba(0, 0, 0, 0.65);
        transform: translateY(0);
    }

    .contact-form-div {
        margin-top: 6rem;
        padding: 5rem;
        font-family: 'Space Mono', monospace;
        width: 50%;
        border: 2px solid black;
    }

    .contact-form {
        display: flex;
        flex-direction: column;
    }

    label {
        font-family: 'Space Mono', monospace;
        color: black;
        font-weight: 100;
        display: flex;
        padding: .75rem;
        flex-direction: column;
        align-items: flex-start;
    }

    .name-email-label {
        display: flex;
        justify-content: space-around;
    }

    input {
        background-color: #D9D9D9;
        border: none;
        opacity: 55%;
        font-size: 1.5rem;
    }

    textarea {
        background-color: #D9D9D9;
        border: none;
        opacity: 55%;
        font-size: 2rem;
        margin-bottom: 5%;
        width: 100%;
    }
}

/* PHONE SIZE VVVVVVVV */

@media screen and (max-width: 1050px) {
    .start-contact-page {
        padding-top: 15rem;
        background-color: #46BBCB;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        padding-bottom: 15rem;
    }

    .contact-me-h2 {
        font-family: 'Space Mono', monospace;
        font-size: 4rem;
        font-weight: 200;
        padding-top: 2rem;
    }

    .letter {
        height: 5rem;
    }

    .contact-buttons-flex {
        display: flex;
        justify-content: space-around;
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .contact-btn-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 45%;
    }


    .contact-btn {
        align-items: center;
        background-color: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: .25rem;
        box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
        box-sizing: border-box;
        color: rgba(0, 0, 0, 0.85);
        cursor: pointer;
        display: inline-flex;
        font-family: 'Space Mono', monospace;
        font-size: 16px;
        font-weight: 600;
        justify-content: center;
        line-height: 1.25;
        margin: 0;
        min-height: 3rem;
        padding: calc(.875rem - 1px) calc(1.5rem - 1px);
        position: relative;
        text-decoration: none;
        transition: all 250ms;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        vertical-align: baseline;
        width: auto;
        margin-bottom: 5%;
    }


    .contact-btn:hover,
    .contact-btn:focus {
        border-color: rgba(0, 0, 0, 0.15);
        box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
        color: rgba(0, 0, 0, 0.65);
    }

    .contact-btn:hover {
        transform: translateY(-1px);
    }

    .contact-btn:active {
        background-color: #F0F0F1;
        border-color: rgba(0, 0, 0, 0.15);
        box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
        color: rgba(0, 0, 0, 0.65);
        transform: translateY(0);
    }

    .contact-form-div {
        margin-top: 6rem;
        padding: 5rem;
        font-family: 'Space Mono', monospace;
        width: 50%;
        border: 2px solid black;
    }

    .contact-form {
        display: flex;
        flex-direction: column;
    }

    label {
        font-family: 'Space Mono', monospace;
        color: black;
        font-weight: 100;
        display: flex;
        padding: .75rem;
        flex-direction: column;
        align-items: flex-start;
    }

    .name-email-label {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    input {
        background-color: #D9D9D9;
        border: none;
        opacity: 55%;
        font-size: 1.5rem;
    }

    textarea {
        background-color: #D9D9D9;
        border: none;
        opacity: 55%;
        font-size: 2rem;
        margin-bottom: 5%;
        width: 95%;
    }
}