@media screen and (min-width: 1050px) {

    .start-project-page {
        padding-top: 10rem;
        background-color: #F87268;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        padding-bottom: 10rem;
    }

    .project-list-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-top: 2%;
        width: 100%;
    }

    .list {
        display: flex;
        flex-direction: column;
        border: 2px solid black;
        padding: 4rem;
        margin-top: 2rem;
    }

    .gold-box-div {
        /* background-color: rgb(255, 215, 0, .35); */
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .project-name-div h3 {
        font-family: 'Space Mono', monospace;
        font-size: 3rem;
        font-weight: 200;
        padding-top: 2rem;
    }

    button {
        color: black;
    }


    .selected-project-css {
        /* color: gold; */
        text-decoration: underline;
        font-size: 2rem;
        font-weight: 400;
        background: none;
        border: none;
        font-family: 'Space Mono', monospace;
        padding-bottom: 5%;
        color: black;
        cursor: pointer;
    }

    .project-name {
        font-size: 2rem;
        font-weight: 400;
        background: none;
        border: none;
        font-family: 'Space Mono', monospace;
        color: black;
        padding-bottom: 5%;
        cursor: pointer;
    }

    .project-name:hover {
        text-decoration: underline;
    }

    .projects-title {
        font-family: 'Space Mono', monospace;
        color: black;
        font-size: 4rem;
        font-weight: 400;
    }

    .desc-and-tech {
        font-size: 1.2rem;
        text-align: center;
        font-family: 'Space Mono', monospace;
        padding: 5%;
    }

    .tech {
        font-weight: bold;
        padding-top: 2rem;
    }

    .github-btn {
        background-color: transparent;
        border: none;
        font-size: 7rem;
    }

    .github-btn:hover {
        cursor: pointer;
    }

    .video-contain {
        position: relative;
        overflow: hidden;
        width: 75%;
        padding-top: 56.25%;
    }

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }


    .not-selected-btn {
        display: none;
    }

    .form-flex {
        display: flex;
        align-items: center;
        flex-direction: column;

    }

    .hostedLink {
        background: none;
        border: 2px solid black;
        font-family: 'Courier New', Courier, monospace;
        font-size: 1rem;
        font-weight: 600;
        height: 45%;
        margin-top: 3%;
        padding: 5%;
    }

    .hostedLink:hover {
        background-color: black;
        color: white;
        cursor: pointer;
    }

}


/* PHONE SIZE VVVVVVVV */

@media screen and (max-width: 1050px) {
    .start-project-page {
        padding-top: 15rem;
        background-color: #F87268;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
        padding-bottom: 10rem;
    }

    .project-list-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding-top: 2%;
        width: 100%;
    }

    button {
        color: black;
    }


    .list {
        display: flex;
        flex-direction: column;
        border: 2px solid black;
        padding: 4rem;
        margin-top: 2rem;
    }

    .gold-box-div {
        /* background-color: rgb(255, 215, 0, .35); */
        color: black;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .project-name-div h3 {
        font-family: 'Space Mono', monospace;
        font-size: 3rem;
        font-weight: 200;
        padding-top: 2rem;
    }



    .selected-project-css {
        /* color: gold; */
        text-decoration: underline;
        font-size: 2rem;
        font-weight: 400;
        background: none;
        border: none;
        font-family: 'Space Mono', monospace;
        padding-bottom: 5%;
        color: black;
    }

    .project-name {
        font-size: 2rem;
        font-weight: 400;
        background: none;
        border: none;
        font-family: 'Space Mono', monospace;
        color: black;
        padding-bottom: 5%;
    }

    .projects-title {
        font-family: 'Space Mono', monospace;
        color: black;
        font-size: 4rem;
        font-weight: 400;
    }

    .desc-and-tech {
        font-size: 1.2rem;
        text-align: center;
        font-family: 'Space Mono', monospace;
        padding: 5%;
    }

    .tech {
        font-weight: bold;
        padding-top: 2rem;
    }

    .github-btn {
        background-color: transparent;
        border: none;
        font-size: 7rem;
    }

    .github-btn:hover {
        cursor: pointer;
    }

    .video-contain {
        position: relative;
        overflow: hidden;
        width: 75%;
        padding-top: 56.25%;
    }

    .responsive-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }


    .not-selected-btn {
        display: none;
    }

    .form-flex {
        display: flex;
        align-items: center;
        flex-direction: column;

    }

    .hostedLink {
        background: none;
        border: 2px solid black;
        font-family: 'Courier New', Courier, monospace;
        font-size: 1rem;
        font-weight: 600;
        height: 45%;
        margin-top: 3%;
        padding: 5%;
    }

    .hostedLink:hover {
        background-color: black;
        color: white;
        cursor: pointer;
    }
}