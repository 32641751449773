@media screen and (min-width: 1050px) {

    .home-computer-div {
        padding-top: 10rem;
        height: 90vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    @keyframes wobble {
        0% {
            transform: translateX(0%);
        }

        15% {
            transform: translateX(-25%) rotate(-5deg);
        }

        30% {
            transform: translateX(20%) rotate(3deg);
        }

        45% {
            transform: translateX(-15%) rotate(-3deg);
        }

        60% {
            transform: translateX(10%) rotate(2deg);
        }

        75% {
            transform: translateX(-5%) rotate(-1deg);
        }

        100% {
            transform: translateX(0%);
        }
    }

    .home-computer {
        animation: wobble 5s;
        height: 30rem;
    }
}

/* PHONE SIZE VVVVVVVV */

@media screen and (max-width: 1050px) {

    .home-computer-div {
        padding-top: 15rem;
        height: 90vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    @keyframes wobble {
        0% {
            transform: translateX(0%);
        }

        15% {
            transform: translateX(-25%) rotate(-5deg);
        }

        30% {
            transform: translateX(20%) rotate(3deg);
        }

        45% {
            transform: translateX(-15%) rotate(-3deg);
        }

        60% {
            transform: translateX(10%) rotate(2deg);
        }

        75% {
            transform: translateX(-5%) rotate(-1deg);
        }

        100% {
            transform: translateX(0%);
        }
    }

    .home-computer {
        animation: wobble 5s;
        height: 20rem;
    }

}