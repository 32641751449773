@media screen and (min-width: 1050px) {

    .nav-bar-container {
        /* background-color: rgb(255, 255, 255, 0.5); */
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: 'Space Mono', monospace;
        /* margin: 2rem;s */
        /* padding: 2rem; */
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;
        transition: background 1s ease
    }

    .full-screen-hide {
        display: none;
    }

    .exit-nav-hide {
        display: none;
    }

    .icon {
        display: none;
    }

    .nav-links-ul-closed {
        display: flex;
        justify-content: space-around;
    }

    .exit-nav-show {
        display: none;
    }

    .name-logo {
        display: flex;
        flex-direction: column;
        padding-left: 1.5rem;
        align-items: center;
    }

    .name-logo h4 {
        font-size: 1.2rem;
        text-align: center;
        font-weight: 500;
        text-decoration: none;
        color: black;
    }

    .name-logo h6 {
        font-size: 1rem;
        margin-top: -1.5rem;
        text-align: center;
        font-weight: 200;
        color: black;
    }

    .nav-links-container {
        width: 78%;
        justify-content: center;
    }

    .nav-links-ul {
        display: flex;
        justify-content: space-around;
    }

    li {
        list-style-type: none;
        font-size: 1.8rem;
        font-weight: 300;
    }

    .nav-link {
        text-decoration: none;
        color: black;
    }

    .nav-link:hover {
        cursor: pointer;
        text-decoration: underline;
    }

}

/* PHONE SIZE VVVVVVVV */

@media screen and (max-width: 1050px) {

    .nav-bar-container {
        /* background-color: rgb(255, 255, 255, 1); */
        backdrop-filter: blur(10px);
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        font-family: 'Space Mono', monospace;
        margin: 0;
        position: fixed;
        height: 8rem;
        top: 0;
        z-index: 10;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    .name-logo {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .name-logo h4 {
        font-size: 1.2rem;
        text-align: center;
        font-weight: 500;
    }

    .name-logo h6 {
        font-size: 1rem;
        margin-top: -1.5rem;
        text-align: center;
        font-weight: 200;
        margin-bottom: 0.5rem;
    }

    .nav-links-container {
        /* width: 100%; */
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .nav-links-ul {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    li {
        list-style-type: none;
        font-size: 1.3rem;
        font-weight: 300;
    }

    .nav-link {
        text-decoration: none;
        color: black;
    }

    .nav-link:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    .nav-links-ul-closed {
        display: none;
    }

    .exit-nav-show {
        margin-top: 0.4rem;
        font-size: 2rem;
        animation-name: fade-in;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }

    .exit-nav-hide {
        display: none;
    }

}